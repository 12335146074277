import React from 'react'; 
import { Link } from 'gatsby';
 
const Pricing = () => (
   
    <div className="flex justify-center items-center">
        <div className="">
           
            <div className="pb-6 md:flex md:flex-row ">
                 <div className="w-full p-8 card-three text-center my-3 md:m-5 rounded-3xl hover:shadow-md">
                    <h1 className="monnify-color-3 font-semibold text-2xl">Collections</h1>
                    <p className="pt-2 tracking-wide">
                        <span className="text-gray-400 align-top">fee </span>
                        <span className="text-3xl font-semibold monnify-color-3">1.5</span>
                        <span className="text-gray-400 font-medium">%</span>
                    </p>
                    <hr className="mt-4 border-1" />
                    <div className="pt-8">
        
                        <p className="font-semibold text-gray-400 text-left pt-5">
                            <span className="material-icons align-middle">
                                Fee is cappped at
                            </span>
                            <span className="pl-2">
                                <span className="monnify-color-3">N2,000</span> per transaction.
                            </span>
                        </p>

                         
                    </div>
                </div> 
                 <div className="w-full p-8 card-three text-center my-3 md:m-5 rounded-3xl  hover:shadow-md">
                    <h1 className="monnify-color-3 font-semibold text-2xl">Reserved Account</h1>
                    <p className="pt-2 tracking-wide">
                        <span className="text-gray-400 align-top">fee </span>
                        <span className="text-3xl font-semibold monnify-color-3">1</span>
                        <span className="text-gray-400 font-medium">%</span>
                    </p>
                    <hr className="mt-4 border-1"/>
                    <div className="pt-8">
                        <p className="font-semibold text-gray-400 text-left">
                        <span className="material-icons align-middle">
                                Fee is cappped at
                            </span>
                            <span className="pl-2">
                                <span className="monnify-color-3">N1,000</span> per transaction.
                            </span>
                        </p>
                        
                    </div>
                </div>
                 <div className="w-full p-8 card-three text-center my-3 md:m-5 rounded-3xl  hover:shadow-md">
                    <h1 className="monnify-color-3 font-semibold text-2xl">Offline Payment</h1>
                    <p className="pt-2 tracking-wide">
                        <span className="text-gray-400 align-top">fee </span>
                        <span className="text-3xl font-semibold monnify-color-3">1</span>
                        <span className="text-gray-400 font-medium">%</span>
                    </p>
                    <hr className="mt-4 border-1"/>
                    <div className="pt-8">
                        <p className="font-semibold text-gray-400 text-left">
                        <span className="material-icons align-middle">
                                Fee is cappped at
                            </span>
                            <span className="pl-2">
                                <span className="monnify-color-3">N1,000</span> per transaction.
                            </span>
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
 
)

export default Pricing;
